import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.four};
  cursor: pointer;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.color.five};
  }
`;

const Anchorlink = ({ className, children, to, ariaLabel }) => (
  <StyledLink className={className} to={to} aria-label={ariaLabel}>
    {children}
  </StyledLink>
);

Anchorlink.defaultProps = {
  className: '',
};

Anchorlink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
};

export default Anchorlink;
