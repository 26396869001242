import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLink = styled.button`
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  background: none;
  border: 1px solid ${({ theme }) => theme.color.three};
  border-radius: 50px;
  color: ${({ theme }) => theme.color.three};
  cursor: pointer;
  display: ${({ isVisible }) => (isVisible ? 'inline-block' : 'none')};
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  margin: 15px 0;
  z-index: 999;
  &:before {
    transform: rotate(45deg);
    top: 12px;
    left: 17px;
  }
  &:after {
    transform: rotate(-45deg);
    top: 12px;
    right: 17px;
  }
  &:before,
  :after {
    position: absolute;
    content: ' ';
    height: 18px;
    width: 2px;
    background: ${({ theme }) => theme.color.three};
  }
  &:hover,
  button.white-outline:hover {
    background-color: ${({ theme }) => theme.color.three};
    color: ${({ theme }) => theme.color.dark};
    opacity: 0.5;
    &:before {
      background: ${({ theme }) => theme.color.two};
      opacity: 0.5;
    }
    &:after {
      background: ${({ theme }) => theme.color.two};
      opacity: 0.5;
    }
  }
`;

const ButtonUp = ({ isVisible, goOnTop }) => {
  return (
    <StyledLink
      onClick={goOnTop}
      isVisible={isVisible}
      aria-label="powrót do góry"
    ></StyledLink>
  );
};

ButtonUp.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  goOnTop: PropTypes.func.isRequired,
};

export default ButtonUp;
