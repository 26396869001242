import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Phone, Envelope, Clock } from '@styled-icons/fa-solid';
import logo from '../img/logo_skup.svg';

const StyledWrapper = styled.div`
  color: #474747;
  background-color: ${({ theme }) => theme.color.two};
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
`;

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 55px auto 0;
  padding: 30px 0;
  max-width: 90%;
  ${({ theme }) => theme.mq.md} {
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  }
  ${({ theme }) => theme.mq.lg} {
    max-width: 1024px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }
`;

const StyledQuarter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  ${({ theme }) => theme.mq.md} {
    width: ${({ width }) => width};
  }
  ${({ theme }) => theme.mq.lg} {
    width: calc(90vw / 4);
    justify-content: center;
    margin-bottom: 0;
  }
`;

const IconBox = styled.div`
  display: flex;
  width: 25%;
  justify-content: flex-end;
  margin-right: 15px;
  color: ${({ theme }) => theme.color.four};
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  font-size: ${({ theme }) => theme.font.size.xs};
`;

const StyledImgLogo = styled.img`
  width: 100%;
`;

const SyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.five};
  margin-left: 0px;

  &:hover {
    color: ${({ theme }) => theme.color.four};
  }
  &:active {
    color: ${({ theme }) => theme.color.four};
  }
`;

const TopBar = () => (
  <StyledWrapper>
    <StyledHeader>
      <StyledQuarter width="100%">
        <Link to="/">
          <StyledImgLogo width={176} height={39} src={logo} alt="logo" />
        </Link>
      </StyledQuarter>
      <StyledQuarter width="33%">
        <IconBox>
          <Phone size="25px" title="Telefon" />
        </IconBox>
        <TextBox>
          <strong>Zadzwoń:</strong>
          <SyledLink aria-label="zadzwoń" href="tel:+48731461112">
            731 461 112
          </SyledLink>
        </TextBox>
      </StyledQuarter>
      <StyledQuarter width="33%">
        <IconBox>
          <Envelope size="25px" title="Email" />
        </IconBox>
        <TextBox>
          <strong>Napisz:</strong>
          <SyledLink
            aria-label="napisz"
            href="mailto:paletypruszczgdanski@gmail.com"
          >
            palety...@gmail.com
          </SyledLink>
        </TextBox>
      </StyledQuarter>
      <StyledQuarter width="33%">
        <IconBox>
          <Clock size="25px" title="Godziny otwarcia" />
        </IconBox>
        <TextBox>
          <strong>Pon. - Piąt.</strong>
          08.00 - 16.00
        </TextBox>
      </StyledQuarter>
    </StyledHeader>
  </StyledWrapper>
);

export default TopBar;
