import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
*,*::before,*::after {
  box-sizing:border-box;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}
body {
  padding:0;
  margin:0;
  font-family: 'Poppins', sans-serif;
  font-size:16px;
  color: #363636;
  overflow-x: hidden;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    color:#363636;
    font-weight:700;
    font-weight: 600;
    line-height: 1.125;
}
a {
  color: #33b391;
  text-decoration: none;
  :hover {
    color: #363636;
  }
}
p:not(:last-child) {
  margin-bottom:1em;
}
h2 {
    font-size: 1.75em;
    margin-bottom: 0.5714em;
}
h2:not(:first-child) {
    margin-top: 1.1428em;
}

`;

export default GlobalStyle;
