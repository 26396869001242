import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Hamburger from './Hamburger';
import MenuMobile from './MenuMobile';
import Menu from './Menu';
import ButtonUp from './ButtonUp';

const StyledWrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  background: ${({ isOnTop, theme }) =>
    isOnTop ? theme.color.four : `rgba(54,54,54, 0.4)`};
  flex-wrap: wrap;
  width: 100%;
  height: 55px;
  z-index: 9999;
  transition: background 0.4s 0.3s ease-in-out;
`;

function Navbar() {
  const [isMenuOpen, setMenuState] = useState(false);
  const [isTop, setTopPosition] = useState(true);
  const [isVisibleButton, setVisible] = useState(false);

  const goOnTopHandler = () => {
    window.scrollTo(0, 0);
  };

  const handler = useCallback(() => {
    const position = window.scrollY;
    const windowHeight = window.outerHeight;
    setTopPosition(position < 275 || false);
    setVisible(position > windowHeight || false);
  });

  useEffect(() => {
    window.addEventListener('scroll', handler, { passive: true });

    return () => window.removeEventListener('scroll', handler);
  }, []);

  const toggleMenuState = () => {
    setMenuState(!isMenuOpen);
  };

  return (
    <StyledWrapper isOnTop={isTop}>
      <Menu />
      <Hamburger onClick={toggleMenuState} isOpen={isMenuOpen} />
      <MenuMobile closeMenu={() => toggleMenuState()} isOpen={isMenuOpen} />
      <ButtonUp isVisible={isVisibleButton} goOnTop={goOnTopHandler} />
    </StyledWrapper>
  );
}

export default Navbar;
