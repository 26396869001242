import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({ theme }) => theme.color.three};
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  transition: transform 0.3s ease-in-out;
  z-index: 9990;
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;

const MenuLinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

const MenuLink = styled.li`
  margin: 15px 0;
  list-style: none;
  font-size: ${({ theme }) => theme.font.size.m};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-display: swap;
  text-transform: uppercase;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: opacity 0.4s 0.3s ease-in-out;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.one};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.one};
`;
const menuItems = [
  { name: 'Home', href: '' },
  { name: 'oferta', href: '#oferta' },
  { name: 'blog', href: 'blog' },
  { name: 'kontakt', href: '#contact' },
];

const MenuMobile = ({ isOpen, closeMenu }) => (
  <StyledWrapper isOpen={isOpen}>
    <MenuLinksWrapper>
      {menuItems.map((item) => (
        <MenuLink onClick={closeMenu} isOpen={isOpen} key={item.name}>
          <StyledLink aria-label="przycisk menu" to={`/${item.href}`}>
            {item.name}
          </StyledLink>
        </MenuLink>
      ))}
    </MenuLinksWrapper>
  </StyledWrapper>
);

MenuMobile.propTypes = {
  isOpen: PropTypes.bool,
  closeMenu: PropTypes.func.isRequired,
};

MenuMobile.defaultProps = {
  isOpen: false,
};

export default MenuMobile;
