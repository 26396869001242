import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '../assets/styles/GlobalStyles';
import { theme } from '../assets/styles/theme';

import Footer from './Footer';
import Navbar from './Navbar';
import TopBar from './TopBar';
import useSiteMetadata from './SiteMetadata';

const TemplateWrapper = ({ children, schemaMarkup }) => {
  const { title, description, lang } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} lang={lang} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {schemaMarkup && (
          <script type="application/ld+json">
            {JSON.stringify(schemaMarkup)}
          </script>
        )}
      </Helmet>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <TopBar />
        <Navbar />
        <div>{children}</div>
        <Footer />
      </ThemeProvider>
    </div>
  );
};

TemplateWrapper.defaultProps = {
  schemaMarkup: {},
};

TemplateWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  schemaMarkup: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
};

export default TemplateWrapper;
