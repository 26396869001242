import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 9990;
  }
`;

const MenuLinksWrapper = styled.ul`
  ${({ theme }) => theme.mq.lg} {
    display: flex;
    margin: 0;
    padding: 0;
  }
`;

const MenuLink = styled.li`
  ${({ theme }) => theme.mq.lg} {
    margin-right: 45px;
    list-style: none;
    font-size: ${({ theme }) => theme.font.size.xxs};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-transform: uppercase;
    position: relative;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.one};
  transition: color 0.2s 0.1s ease-in-out;
  :hover {
    color: ${({ theme }) => theme.color.one};
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -3px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.color.one};
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }
  &:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }
`;

const menuItems = [
  { name: 'Home', href: '' },
  { name: 'oferta', href: '#oferta' },
  { name: 'blog', href: 'blog' },
  { name: 'kontakt', href: '#contact' },
];

const Menu = () => (
  <StyledWrapper>
    <MenuLinksWrapper>
      {menuItems.map((item) => (
        <MenuLink key={item.name}>
          <StyledLink aria-label="kliknij element menu" to={`/${item.href}`}>
            {item.name}
          </StyledLink>
        </MenuLink>
      ))}
    </MenuLinksWrapper>
  </StyledWrapper>
);

export default Menu;
