import React from 'react';
import styled from 'styled-components';

import Anchorlink from './Anchorlink';
import logo from '../img/logo_skup.svg';

const StyledWrapper = styled.footer`
  color: ${({ theme }) => theme.color.one};
  background-color: ${({ theme }) => theme.color.four};
`;

const LogoContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 25px;
  max-width: 1140px;
  margin: 0 auto;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  flex-direction: column;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`;

const HalfContainer = styled.div`
  align-items: ${({ position }) => position};
  display: flex;
  padding: 10px 10px;
  flex-direction: column;
  ${({ theme }) => theme.mq.lg} {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 10px 20px;
  }
`;

const MenuList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  margin-right: 15px;
`;

const WhiteAnchorlink = styled(Anchorlink)`
  color: ${({ theme }) => theme.color.one};
`;

const Footer = () => {
  return (
    <StyledWrapper id="stopka">
      <LogoContainer>
        <img src={logo} width={176} height={39} alt="Skup palet logo" />
      </LogoContainer>
      <FooterContainer>
        <HalfContainer position="flex-end">
          <MenuList>
            <ListItem>
              <WhiteAnchorlink ariaLabel="menu home" to="/">
                Home
              </WhiteAnchorlink>
            </ListItem>
            <ListItem>
              <WhiteAnchorlink ariaLabel="menu oferta" to="/#oferta">
                Oferta
              </WhiteAnchorlink>
            </ListItem>
            <ListItem>
              <WhiteAnchorlink ariaLabel="menu blog" to="/blog">
                Blog
              </WhiteAnchorlink>
            </ListItem>
            <ListItem>
              <WhiteAnchorlink ariaLabel="menu kontakt" to="/#kontakt">
                Kontakt
              </WhiteAnchorlink>
            </ListItem>
          </MenuList>
        </HalfContainer>
        <HalfContainer position="flex-start">
          <WhiteAnchorlink
            ariaLabel="polityka prywatności"
            to="/polityka_prywatnosci"
          >
            Polityka prywatności
          </WhiteAnchorlink>
          <div>
            All copyrights reserved © 2020 - Designed & Developed by Giedeé
          </div>
        </HalfContainer>
      </FooterContainer>
    </StyledWrapper>
  );
};

export default Footer;
