export const theme = {
  color: {
    one: '#fff',
    two: '#F4F4F4',
    three: '#61D2B4',
    four: '#33b391',
    five: '#363636',
  },
  font: {
    size: {
      xxs: '1rem',
      xs: '1.2rem',
      s: '1.4rem',
      sm: '2rem',
      m: '2.6rem',
      ml: '3.4rem',
      l: '5.2rem',
      xl: '7.2rem',
      xxl: '9.4rem',
    },
    family: {
      poppins: 'Poppins, sans-serif',
    },
    weight: {
      light: '300',
      medium: '500',
      bold: '700',
    },
  },
  mq: {
    sm: `@media (min-width:576px)`,
    md: `@media (min-width:768px)`,
    lg: `@media (min-width:992px)`,
    xl: `@media (min-width:1400px)`,
    xxl: `@media (min-width:1400px)`,
  },
  contentCSS: {},
};
