import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledHamburger = styled.button`
  padding: 15px;
  border: none;
  background: none;
  position: absolute;
  top: 10px;
  z-index: 9999;
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;

const InnerHamburger = styled.div`
  position: relative;
  width: 20px;
  height: 2px;
  background-color: ${({ theme, isOpen }) =>
    isOpen ? 'transparent' : theme.color.one};
  transition: background-color 0.15s 0.5s ease-in-out;
  ::after,
  ::before {
    content: '';
    position: absolute;
    left: 0;
    width: 20px;
    height: 2px;
    background-color: ${({ theme }) => theme.color.one};
    transition: transform 0.15s 0.5s ease-in-out;
  }
  ::before {
    top: -6px;
    transform: translateY(${({ isOpen }) => (isOpen ? '6px' : '0')})
      rotate(${({ isOpen }) => (isOpen ? '45deg' : '0')});
  }
  ::after {
    top: 6px;
    transform: translateY(${({ isOpen }) => (isOpen ? '-6px' : '0')})
      rotate(${({ isOpen }) => (isOpen ? '-45deg' : '0')});
  }
`;

const Hamburger = ({ isOpen, ...props }) => (
  <StyledHamburger aria-label="hamburger menu" {...props}>
    <InnerHamburger isOpen={isOpen} />
  </StyledHamburger>
);

Hamburger.defaultProps = {
  isOpen: false,
};

Hamburger.propTypes = {
  isOpen: PropTypes.bool,
};
export default Hamburger;
